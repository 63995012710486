import { CloseRounded } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DesktopPrompt } from './Shared/SharedDesktopQuestionComponents'

const INITIAL_CARD_TRANSFORM = 'rotateX(45deg) rotateY(-15deg) rotate(45deg)'

interface Props {
  question: Question
  orientation?: 'desktop' | 'mobile'
}

export const CardChoiceQuestion = ({ question, orientation = 'mobile' }: Props) => {

  const [clickedIndex, setClickedIndex] = useState(-1)
  const [transitioning, setTransitioning] = useState(false)
  const [compact, setCompact] = useState(false)
  const [prompt, setPrompt] = useState(question.prompt)


  useEffect(() => {
    setPrompt(question.prompt || 'What would you like to ask?')
  }, [])

  useEffect(() => {
    setTransitioning(true)
    setTimeout(() => setTransitioning(false), 300)
  }, [clickedIndex])

  const [choices, setChoices] = useState<
    { title: string; subtitle: string; imageUrl: string; color: string; secondaryColor: string }[]
  >([
    {
      title: 'Buffalo Bills',
      subtitle: '+500',
      imageUrl: '/img/temp/bills_logo.png',
      color: '#0a349c',
      secondaryColor: '#7b84e8',
    },
    {
      title: 'Tampa Bay Buccaneers',
      subtitle: '+700',
      imageUrl: '/img/temp/bucs_logo.png',
      color: '#7b0f0f',
      secondaryColor: '#ec5050',
    },
    {
      title: 'Kansas City Chiefs',
      subtitle: '+800',
      imageUrl: '/img/temp/chiefs_logo.png',
      color: '#c8102e',
      secondaryColor: '#f95050',
    },
    {
      title: 'Los Angeles Chargers',
      subtitle: '+1300',
      imageUrl: '/img/temp/chargers_logo.png',
      color: '#352f64',
      secondaryColor: '#415197',
    },
  ])

  return (
    <StyledCardDeckSelector style={{ pointerEvents: transitioning ? 'none' : 'all' }}>
      <DesktopPrompt
        style={{ textAlign: 'center' }}
        placeholder="What would you like to ask?"
        onChange={(event: any) => setPrompt(event.target.value)}
      >{question.prompt}</DesktopPrompt>
      {/* {prompt && <Prompt style={{ color: choices[clickedIndex]?.color || 'white' }}>{prompt}</Prompt>} */}
      <CardDeck style={{ marginTop: clickedIndex === -1 ? '-160px' : 80 }}>
        {choices.map((choice, index) => (
          <Card
            draggable={true}
            key={'card' + index}
            options={choice}
            offsetFromEnd={choices.length - 1 - index}
            selected={clickedIndex === index}
            style={
              clickedIndex === -1
                ? { marginTop: -40, cursor: 'pointer' }
                : clickedIndex === index
                  ? {
                    transform: compact
                      ? 'translate(0) scale(1.5)'
                      : `translate(0, ${-100 - 10 * index}px) scale(1.5)`,
                    marginBottom: -140,
                    marginTop: -130,
                    zIndex: choices.length,
                  }
                  : { marginTop: -130, transform: INITIAL_CARD_TRANSFORM }
            }
            onClick={clickedIndex === -1 ? () => setClickedIndex(index) : undefined}
          >
            {clickedIndex === index && (
              <CloseRounded
                sx={{ position: 'absolute', top: 10, right: 10, color: '#fff7', cursor: 'pointer' }}
                onClick={() => setClickedIndex(-1)}
              />
            )}
            <CardImage src={choice.imageUrl} />
            <CardTitle
              value={choice.title}
              onChange={(event) => {
                let newChoices = [...choices]
                newChoices[index] = {
                  ...newChoices[index],
                  title: event.target.value,
                }
                setChoices(newChoices)
              }}
            />
            <CardSubtitle
              value={choice.subtitle}
              onChange={(event) => {
                let newChoices = [...choices]
                newChoices[index] = {
                  ...newChoices[index],
                  subtitle: event.target.value,
                }
                setChoices(newChoices)
              }}
            />
          </Card>
        ))}
      </CardDeck>
    </StyledCardDeckSelector>
  )
}

const StyledCardDeckSelector = styled.div`
  display: grid;
  font-family: Avenir, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;

  width: 100%;
  height: 100%;
  position: relative;
`

const Prompt = styled.h1`
  margin: 30px;
  text-align: center;
  color: white;
  transition: 400ms ease color;
  text-shadow: 0 0 4px #333a;
`

const CardDeck = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -180px 0 0 -140px;
  user-select: none;
  transform: translate3d(0, 0, 0);
  /* transform-style: preserve-3d; */
  transition: 400ms ease all;

  &::after {
    position: absolute;
    bottom: -27px;
    left: 5%;
    content: '';
    width: 65%;
    height: 10px;
    border-radius: 100%;
    background-image: radial-gradient(rgba(34, 50, 84, 0.04), rgba(34, 50, 84, 0));
  }
`

const Card = styled.div<{
  options: { color?: string; secondaryColor?: string }
  offsetFromEnd: number
  selected?: boolean
}>`
  position: relative;
  width: 280px;
  height: 140px;
  border-radius: 12px;
  color: #fff;
  transform: rotateX(45deg) rotateY(-15deg) rotate(45deg);
  transition: 400ms ease all;
  overflow: hidden;

  ${({ options, offsetFromEnd }) => `
    background-color: ${options.color};
    background-image: linear-gradient(135deg, ${options.secondaryColor}, ${options.color});
    box-shadow: ${offsetFromEnd === 0
      ? `5px 5px 60px rgba(34, 50, 84, 0.1), 1px 1px 0px 1px ${options.color}`
      : `20px 20px 60px rgba(34, 50, 84, 0.5), 1px 1px 0px 1px ${options.color}`
    };
    z-index: ${offsetFromEnd};
  `}

  &::after {
    position: absolute;
    top: -40px;
    left: 0;
    content: '';
    width: 200%;
    height: 200%;
    background-image: linear-gradient(
      60deg,
      rgba(255, 255, 255, 0) 20%,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0) 80%
    );
    transform: translateX(-100%);
  }

  ${({ selected }) =>
    !selected &&
    `
  &:hover {
    transform: rotateX(30deg) rotateY(-15deg) rotate(30deg) translate(-25px, 50px);
  }

  &:hover::after {
    transform: translateX(100%);
    transition: all 1.2s ease-in-out;
  }
  `}
`

const CardImage = styled.img`
  margin-top: 15px;
  margin-left: 15px;
  width: 70px;
  pointer-events: none;
`

const CardTitle = styled.input`
  position: absolute;
  bottom: 32px;
  left: 15px;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  background-color: transparent;
  outline: none !important;
  border: none !important;
  color: white;
`

const CardSubtitle = styled.input`
  position: absolute;
  font-weight: 600;
  bottom: 15px;
  left: 15px;
  padding: 0;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.8;
  background-color: transparent;
  outline: none !important;
  border: none !important;
  color: white;
`
