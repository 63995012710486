import { useAppDispatch } from 'app/hooks'
import { useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { DesktopField, DesktopPrompt } from './Shared/SharedDesktopQuestionComponents'
import { Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const ImageChoiceQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const [selectedChoice, setSelectedChoice] = useState<number>(-1)
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const handleVote = (choiceValue: any) => {
    if (choiceValue === -1) {
      return alert('Please tap a choice to select')
    }
    onVote && onVote(question, choiceValue)
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: question.choices[selectedChoice] as unknown as any,
        utm: window.location.search,
      })
    }
    setSelectedChoice(-1)
    dispatch(vote(choiceValue))
  }

  const isQuad = question.choices.length === 4
  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopField>
          <DesktopPrompt>{question.prompt}</DesktopPrompt>
        </DesktopField>
        <DesktopChoices>
          {question.choices.map((choice, index) => (
            <DesktopChoiceWrapper key={index}>
              {choice.label}
              <DesktopChoice
                src={choice?.image?.url}
                onClick={() => handleVote(choice.value)}
              />
            </DesktopChoiceWrapper>
          ))}
        </DesktopChoices>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Choices style={{ flexDirection: isQuad ? 'row' : 'column' }}>
          {question.choices.map((choice: any, index: number) => (
            <ChoiceContainer
              key={index}
              isQuad={isQuad}
              onClick={() =>
                selectedChoice === choice.value ? setSelectedChoice(-1) : setSelectedChoice(choice.value)
              }
            >
              <ChoiceLabel position={(isQuad && index < 2) || (!isQuad && index < 1) ? 'top' : 'bottom'}>
                {choice.label}
              </ChoiceLabel>
              <ChoiceImage src={choice?.image?.url} />
            </ChoiceContainer>
          ))}
        </Choices>
        {question.prompt && (
          <PromptWrapper>
            <Prompt>{question.prompt}</Prompt>
          </PromptWrapper>
        )}
      </Tile>
    )
  }
}

const DesktopTile = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #008080;
  color: #ffffff;
`

const DesktopChoices = styled.div`
  display: flex;
  flex-direction: row;
`

const DesktopChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: -10px;
  color: #ffffff;
  width: 25%;
  bottom: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    background: #ffffff;
    color: #008080;
  }
`

const DesktopChoice = styled.img`
  width: 100%;
  margin-top: 10px;
`

const PromptWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
`

const Prompt = styled.div`
  padding: 60px 30px;
  width: calc(100% - 60px);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`

const Choices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ChoiceContainer = styled.div<{ isQuad: boolean }>`
  position: relative;
  width: ${({ isQuad }) => (isQuad ? '50%' : '100%')};
  margin-bottom: -4px;
  aspect-ratio: ${({ isQuad }) => (isQuad ? '0.639' : '1.279')};
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceLabel = styled.div<{ position: 'top' | 'bottom' }>`
  width: 100%;
  font-weight: 600;
  color: #555555;
  backdrop-filter: blur(3px);
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  ${({ position }) => (position === 'top' ? `top: 0;` : `bottom: 0;`)}
`

const ChoiceImage = styled.img`
  height: 100%;
  width: 100%;
`
