import { useState, useRef } from 'react'
import { Outlet } from 'react-router-dom' //useParams
import YouTube from 'react-youtube'

import styled from 'styled-components'

import { useAppSelector } from '../app/hooks'

const isMobile = window.innerWidth < 430

const REDIRECT_URL = 'https://secure.winred.com/diehl-for-ma/donate-today?utm_source=bwi'

const opts: any = {
  width: isMobile ? window.innerWidth - 20 : '640px',
  height: isMobile ? window.innerHeight * 0.7 : '390px',
  maxHeight: 200,
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    // autoplay: 1,
    modestbranding: 1,
    // enableJsApi: 1,
    rel: 0,
    end: 66,
  },
}

export function VideoRedirectSB() {
  document.title = 'Blueprint for the Bay State - Small Business'
  // const navbarImageUrl = useAppSelector((state) => state.page.navbarImageUrl)

  const videoPlayer = useRef(null)

  function onReady(event: any) {
    setTimeout(play, 50)
  }

  function onEnd(event: any) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo()
    window.location.href = REDIRECT_URL
  }

  function onError(event: any) {
    console.log(event)
  }

  function play() {
    try {
      ;(videoPlayer.current as any).getInternalPlayer().playVideo()
    } catch (e) {}
  }

  return (
    <Fullscreen>
      <VideoWrapper>
        <YouTube
          ref={videoPlayer}
          videoId='8gWyFNHDQL0'
          opts={opts}
          onReady={onReady}
          onEnd={onEnd}
          onError={onError}
        />
      </VideoWrapper>
      <a style={{ textDecoration: 'none', color: 'white' }} href={REDIRECT_URL}>
        <RedirectButton>Support Geoff's Campaign</RedirectButton>
      </a>
    </Fullscreen>
  )
}

const Fullscreen = styled.div`
  position: relative;
  margin: 0 auto;
  text-align: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(45deg, #d2e6ff, rgb(235 243 255));
  line-height: 0;
  text-align: center;
`

const VideoWrapper = styled.div`
  margin: 0 auto;
  padding: 80px 0 20px 0;
  border-radius: 20px;
  width: max-content;
  overflow: hidden;
`

const RedirectButton = styled.p`
  background-color: #2d2f67;
  border-radius: 4px;
  position: relative;
  line-height: initial;
  width: calc(100% - 20px);
  max-width: 640px;
  margin: 10px auto;
  padding: 14px 0;
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: 600;
  font-family: sans-serif;
`
