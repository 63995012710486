import { useAppDispatch } from 'app/hooks'
import { useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { DesktopEngagement, DesktopField, DesktopImage, DesktopPrompt, DesktopTile } from './Shared/SharedDesktopQuestionComponents'
import { Background, OpacityBottom, OpacityTop, Prompt, Submit, Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export function RateQuestion({ question, survey, orientation = 'mobile', onVote }: Props) {

  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const [slider, setSlider] = useState<number>(0.5)

  const handleVote = () => {
    onVote && onVote(question)
    let quantizedRating = Math.floor(slider * 5)
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: [{ value: quantizedRating, label: '' }],
        utm: window.location.search,
      })
    }
    dispatch(vote(quantizedRating))
  }

  const Slider = () => {
    return (
      <SlideBar value={slider}>
        <Selector value={slider} />
      </SlideBar>
    )
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopImage src={question.image?.url} />
        <DesktopField>
          <DesktopPrompt>{question.prompt}</DesktopPrompt>
          <DesktopEngagements>
            <Slider />
            <RateLabels>
              <p style={{ textAlign: 'left', width: '35%%', marginRight: '15%' }}>
                {question.choices[0]?.label || ''}
              </p>
              <p style={{ textAlign: 'right', width: '35%%', marginLeft: '15%' }}>
                {question.choices[1]?.label || ''}
              </p>
            </RateLabels>
            <DesktopEngagement onClick={() => handleVote()}>Submit</DesktopEngagement>

          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt>{question.prompt}</Prompt>
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Slider />
            <RateLabels>
              <p style={{ textAlign: 'left', width: '35%%', marginRight: '15%' }}>
                {question.choices[0]?.label || ''}
              </p>
              <p style={{ textAlign: 'right', width: '35%%', marginLeft: '15%' }}>
                {question.choices[1]?.label || ''}
              </p>
            </RateLabels>
            <Submit>{'Submit'}</Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const Engagements = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 40px 0;
`

const SlideBar = styled.div<{ value: number }>`
  position: relative;
  height: 10px;
  border-radius: 5px;
  width: calc(100% - 80px);
  margin-bottom: 20px;
  background: linear-gradient(90deg, #0000ff 1%, #e2ccdf 45%, #e5b7c7 55%, #ff0000 100%);
  z-index: 2;
`

const Selector = styled.div<{ value: number }>`
  position: absolute;
  height: 30px;
  width: 30px;
  margin-left: calc(${({ value }) => value * 100 + '%'} - 15px);
  margin-top: -10px;
  background: #ffffff;
  border-radius: 15px;
`

const RateLabels = styled.div`
  width: calc(100% - 80px);
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
`

const DesktopEngagements = styled.div`
  display: block;
  margin-top: 30px;
`
