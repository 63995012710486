import { useAppDispatch } from 'app/hooks'
import { useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import {
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile
} from './Shared/SharedDesktopQuestionComponents'

import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Submit,
  Tile
} from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const TextQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()
  const [response, setResponse] = useState(question.prompt)

  function handleVote() {
    onVote && onVote(question)
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: [{ value: -1, label: response }],
        utm: window.location.search,
      })
    }
    dispatch(vote(-1))
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopImage src={question.image?.url} />
        <DesktopField>
          <DesktopPrompt>{question.prompt}</DesktopPrompt>
          <DesktopTextArea value={response} onChange={(e) => setResponse(e.target.value)} placeholder={'Type your thoughts here...'} />
          <DesktopEngagements>
            <DesktopEngagement onClick={() => handleVote()}>Submit</DesktopEngagement>
            <DesktopEngagement>Skip</DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt>{question.prompt}</Prompt>
        </OpacityTop>
        <TextInput placeholder={'Type your thoughts here...'} />
        <OpacityBottom>
          <Engagements style={{ marginRight: '20px', marginLeft: '20px' }}>
            <Submit style={{ textAlign: 'center' }}>Skip</Submit>
            <Submit style={{ textAlign: 'center' }}>Submit</Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const DesktopTextArea = styled.textarea`
  box-sizing: border-box;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  font-family: Montserrat;
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: #008080;
  background: rgba(255, 255, 255, 0.9);
  // background: rgba(0,0,0,0.8);
  outline: none;
  border: none;
  resize: none;
`

const DesktopEngagement = styled.div`
  font-size: 16px;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`

const TextInput = styled.textarea`
  width: calc(100% - 80px);
  margin-left: 20px;
  padding: 20px;
  font-family: Montserrat;
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.8);
  // background: rgba(0,0,0,0.8);
  outline: none;
  border: none;
  resize: none;
`
