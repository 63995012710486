import styled from 'styled-components/macro'

export const DesktopTile = styled.div<{ styles?: any }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  flex-direction: ${({ styles }) => styles?.layout || 'row'};
  color: ${({ styles }) => styles?.color || '#ffffff'};
  background: ${({ styles }) => styles?.background || '#008080'};
`

export const DesktopImage = styled.img`
  cursor: pointer;
  height: 100%;
  width: 359px;
  background: rgba(255, 255, 255, 0.3);
`

export const DesktopField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 50px;
`

export const DesktopPrompt = styled.p`
  font-size: 30px;
  font-weight: 600;
  &::placeholder {
    color: inherit;
  }
`

export const DesktopEngagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
`

export const DesktopEngagement = styled.div`
  font-size: 16px;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`
