import styled from 'styled-components/macro'
import { BooleanQuestion } from './BooleanQuestion'
import { CardChoiceQuestion } from './CardChoiceQuestion'
import { ImageChoiceQuestion } from './ImageChoiceQuestion'
import { ImageRankQuestion } from './ImageRankQuestion'
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion'
import { RankQuestion } from './RankQuestion'
import { RateQuestion } from './RateQuestion'
import { TextQuestion } from './TextQuestion'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const Question = ({ question, orientation = 'mobile', survey, onVote }: Props) => {
  switch (question.type) {
    case 'boolean':
      return <BooleanQuestion question={question} survey={survey} onVote={onVote} orientation={orientation} />
    case 'multiple-choice':
      return <MultipleChoiceQuestion question={question} survey={survey} onVote={onVote} />
    case 'image-choice':
      return <ImageChoiceQuestion question={question} survey={survey} onVote={onVote} orientation={orientation} />
    case 'rank':
      return <RankQuestion question={question} survey={survey} onVote={onVote} orientation={orientation} />
    case 'image-rank':
      return <ImageRankQuestion question={question} survey={survey} onVote={onVote} orientation={orientation} />
    case 'rate':
      return <RateQuestion question={question} survey={survey} onVote={onVote} orientation={orientation} />
    case 'text':
      return <TextQuestion question={question} survey={survey} onVote={onVote} orientation={orientation} />
    case 'card-multiple-choice':
      return <CardChoiceQuestion question={question} orientation={orientation} />
    default:
      return <NoOp />
  }
}

const NoOp = styled.div``
