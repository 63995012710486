import { useAppDispatch } from 'app/hooks'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'

import {
  DesktopEngagement,
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile
} from './Shared/SharedDesktopQuestionComponents'
import { Background, Prompt, Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export function BooleanQuestion({ question, survey, onVote, orientation = 'mobile' }: Props) {

  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()


  function handleVote(voteValue: number): void {
    onVote && onVote(question, voteValue)
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: [question.choices[voteValue]],
        utm: window.location.search,
      }) // TODO: question.choices[voteValue].value
    }
    dispatch(vote(voteValue))
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopImage
          src={question.image!.url}
        />
        <DesktopField>
          <DesktopPrompt>{question.prompt}</DesktopPrompt>
          <DesktopEngagements>
            <DesktopEngagement onClick={() => handleVote(0)}>Yes</DesktopEngagement>
            <DesktopEngagement onClick={() => handleVote(1)}>No</DesktopEngagement>
            <DesktopEngagement onClick={() => handleVote(2)}>Not sure</DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt>
            {question.prompt}
          </Prompt>
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Engagement onClick={() => handleVote(0)} src={'/img/engagement_icons/no.png'} />
            <Engagement onClick={() => handleVote(1)} src={'/img/engagement_icons/not-sure.png'} />
            <Engagement onClick={() => handleVote(2)} src={'/img/engagement_icons/yes.png'} />
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const OpacityTop = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

const OpacityBottom = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`

const Engagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 40px 20px 40px;
`

const Engagement = styled.img`
  height: 70px;
  cursor: pointer;
  user-select: none;
  user-drag: none;
`