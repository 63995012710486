import { useAppDispatch } from 'app/hooks'
import { useEffect, useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { DesktopField, DesktopPrompt } from './Shared/SharedDesktopQuestionComponents'
import { Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const ImageRankQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const [rank, setRank] = useState<Choice[]>([])
  const isQuad = question.choices.length === 4

  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  useEffect(() => {
    console.log(rank)
  }, [rank])

  const handleVote = (submittedRank: any) => {
    if (submittedRank.length < question.choices.length) {
      alert('Please rank all choices')
      return
    }
    onVote && onVote(question)
    setRank([])
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: submittedRank,
        utm: window.location.search,
      })
    }
    dispatch(vote(submittedRank[0].value))
  }


  const Choice = ({ choice }: { choice: Choice }) => {
    const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
    return (
      <ChoiceContainer
        isQuad={isQuad}
        onClick={() => {
          if (ranking) return setRank(rank.filter((eachChoice) => eachChoice !== choice))
          return setRank([...rank, choice])
        }}
      >
        {choice.image?.url && <ChoiceImage src={choice.image.url} />}
      </ChoiceContainer>
    )
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopField>
          <DesktopPrompt>{question.prompt}</DesktopPrompt>
        </DesktopField>
        <DesktopChoices>
          {question.choices.map((choice, index) => (
            <DesktopChoiceWrapper
              key={index}
              rank={rank.map(choice => choice.value).indexOf(index) + 1}
              onClick={() => {
                const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
                console.log(ranking)
                if (ranking) return setRank(rank.filter((eachChoice) => eachChoice !== choice))
                return setRank([...rank, choice])
              }}>
              {choice.label}
              <DesktopChoice

                src={choice?.image?.url}
              />
              {rank.map(choice => choice.value).indexOf(index + 1) + 1}
            </DesktopChoiceWrapper>
          ))}
        </DesktopChoices>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Choices style={{ flexDirection: isQuad ? 'row' : 'column' }}>
          {question.choices.map((choice: any, index: any) => {
            return <Choice key={index} choice={choice} />
          })}
        </Choices>
        {question.prompt && (
          <PromptWrapper>
            <Prompt>{question.prompt}</Prompt>
          </PromptWrapper>
        )}
      </Tile>
    )
  }
}

const DesktopTile = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #008080;
  color: #ffffff;
`

const DesktopChoices = styled.div`
  display: flex;
  flex-direction: row;
`

const DesktopChoiceWrapper = styled.div<{ rank: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: -10px;
  color: #ffffff;
  width: 25%;
  bottom: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${({ rank }) => (
    rank !== 0 &&
    `transform: translateY(-10px);
      background: #ffffff;
      color: #008080;`
  )}

  &:hover {
    transform: translateY(-10px);
    background: #ffffff;
    color: #008080;
  }
`

const DesktopChoice = styled.img`
  width: 100%;
  margin-top: 10px;
`

const PromptWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
`

const Prompt = styled.div`
  padding: 60px 30px;
  width: calc(100% - 60px);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`

const Choices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ChoiceContainer = styled.div<{ isQuad: boolean }>`
  width: ${({ isQuad }) => (isQuad ? '50%' : '100%')};
  margin-bottom: -4px;
  aspect-ratio: ${({ isQuad }) => (isQuad ? '0.639' : '1.279')};
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceImage = styled.img`
  height: 100%;
  width: 100%;
`
