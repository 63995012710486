import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { SurveyNavbar } from './components/SurveyNavbar'
import { PageNavbarWFD } from './components/PageNavbarWFD'
import { PageNavbarLE } from './components/PageNavbarLE'
import { PageNavbarLA } from './components/PageNavbarLA'
import { PageNavbarInfra } from './components/PageNavbarInfra'
import { Survey } from './components/Survey'
import { VideoRedirectWFD } from './components/VideoRedirectWFD'
import { VideoRedirectLE } from './components/VideoRedirectLE'
import { VideoRedirectLA } from './components/VideoRedirectLA'
import { VideoRedirectInfra } from './components/VideoRedirectInfra'
import { VideoRedirectVax } from 'components/VideoRedirectVax'
import { VideoRedirectSB } from 'components/VideoRedirectSB'
import { VideoRedirectPS } from 'components/VideoRedirectPS'

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path='/survey/:mask' element={<SurveyNavbar />}>
          <Route path='/survey/:mask' element={<Survey />} />
        </Route>
        <Route path='/page/WomenForDiehl' element={<PageNavbarWFD />}>
          <Route path='/page/WomenForDiehl' element={<VideoRedirectWFD />} />
        </Route>
        <Route path='/page/B2BTour' element={<PageNavbarLE />}>
          <Route path='/page/B2BTour' element={<VideoRedirectLE />} />
        </Route>
        <Route path='/page/MeetLeah' element={<PageNavbarLA />}>
          <Route path='/page/MeetLeah' element={<VideoRedirectLA />} />
        </Route>
        <Route path='/page/daily-diehl-infra' element={<PageNavbarInfra />}>
          <Route path='/page/daily-diehl-infra' element={<VideoRedirectInfra />} />
        </Route>
        <Route path='/page/daily-diehl-vaccine-mandates' element={<PageNavbarInfra />}>
          <Route path='/page/daily-diehl-vaccine-mandates' element={<VideoRedirectVax />} />
        </Route>
        <Route path='/page/daily-diehl-small-business' element={<PageNavbarInfra />}>
          <Route path='/page/daily-diehl-small-business' element={<VideoRedirectSB />} />
        </Route>
        <Route path='/page/daily-diehl-political-agendas-in-school' element={<PageNavbarInfra />}>
          <Route path='/page/daily-diehl-political-agendas-in-school' element={<VideoRedirectPS />} />
        </Route>
      </Routes>
    </Router>
  )
}
