import { useAppDispatch } from 'app/hooks'
import { useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { DesktopPrompt } from './Shared/SharedDesktopQuestionComponents'
import { Engagements, Submit, Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export function MultipleChoiceQuestion({ question, survey, onVote, orientation }: Props) {

  const [choice, setChoice] = useState<number>(-1)

  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  function handleVote(choiceValue: any) {
    if (choiceValue === -1) {
      return alert('Please tap a choice to select')
    }
    onVote && onVote(question, choiceValue)
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: question.choices[choiceValue],
        utm: window.location.search,
      })
    }
    setChoice(-1)
    dispatch(vote(choiceValue))
  }

  return (
    <Tile id={question._id}>
      <DesktopPrompt>{question.prompt}</DesktopPrompt>
      <Choices>
        {question.choices.map((choice: any, index: any) => {
          return <Choice key={index} choice={choice} />
        })}
      </Choices>
      <Engagements>
        <Submit>{'Submit'}</Submit>
      </Engagements>
    </Tile>
  )

  function Choice(props: { choice: Choice }) {
    return (
      <ChoiceContainer
        selected={choice.value === choice}
        onClick={() => (choice === choice.value ? setChoice(-1) : setChoice(choice.value))}
      >
        {choice.image?.url && <ChoiceImage src={choice.image.url} />}
        <ChoiceLabel>{choice.label}</ChoiceLabel>
      </ChoiceContainer>
    )
  }
}

const Choices = styled.div`
  padding: 0px;
`

const ChoiceContainer = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px;
  width: calc(100% - 80px);
  padding: 6px 40px;
  background: ${({ selected }) => (selected ? 'rgba(255,255,255,0.7)' : 'none')};
  color: ${({ selected }) => (selected ? '#08324C' : '#ffffff')};
  transition: all 0.25s;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceImage = styled.img`
  align-self: center;
  height: 68px;
  width: 68px;
  min-width: 68px;
  max-width: 68px;
  border-radius: 40px;
  border: solid 2px #ffffff;
`

const ChoiceLabel = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
`
